<script setup lang="ts">
import { Ref, inject, ref } from "vue";
import { Step1Vue, Step2Vue } from "../step";
import { MEMBERSHIP_PLOC, usePlocState } from "@/utils";
import { MemberShipRequest } from "neos-core";
import { step1Form, step2Form } from "@/entities";
interface Translations {
	overzicht: string;
	inschrijvenLidmaatschap: string;
	nieuwLid: string;
	totaal: string;
	hulpNodig: string;
}
const props = defineProps<{
	amount: number;
	clubName: string;
	clubId: string;
	helpNeededText: string;
	translations: Translations;
}>();
const errorMessage: Ref<string | undefined> = ref();
const membershipPloc = inject(MEMBERSHIP_PLOC)!;
const membershipState = ref(usePlocState(membershipPloc));
const isLoading = ref(false);

const isStep1Active = ref(true);
const isStep2Active = ref(false);
const totalAmount = ref(props.amount);
const extraMember = ref(false);
const membershipRequest: MemberShipRequest = {
	isExistingMember: false,
};
function clickedNextStep1(payload: { hasExtra: boolean; formData: step1Form }) {
	setMemberPersonalInfo(payload.formData);
	if (payload.hasExtra) {
		totalAmount.value = props.amount * 2;
		extraMember.value = true;
		setMemberPartnerPersonalInfo(payload.formData);
	}
	if (membershipRequest.isExistingMember) {
		if (membershipRequest.partner) {
			if (membershipRequest.partner.isExistingMember) {
				requestMembership();
			} else {
				isStep1Active.value = false;
				isStep2Active.value = true;
			}
		} else {
			requestMembership();
		}
	} else {
		isStep1Active.value = false;
		isStep2Active.value = true;
	}
}

function clickedNextStep2(formData: step2Form) {
	isLoading.value = true;
	setMemeberAddressInfo(formData);
	requestMembership();
}

function setMemberPersonalInfo(formData: step1Form) {
	if (formData.memberNumber != "") {
		membershipRequest.isExistingMember = true;
		membershipRequest.memberNumber = formData.memberNumber;
	} else {
		membershipRequest.lastName = formData.lastname;
		membershipRequest.firstName = formData.firstName;
		membershipRequest.gender = formData.gender;
		membershipRequest.birthDate = formatDate(formData.birthDate);
		membershipRequest.email = formData.email;
		membershipRequest.phoneNumber = formData.phoneNumber;
	}
}

function setMemeberAddressInfo(formData: step2Form) {
	membershipRequest.street = formData.address;
	membershipRequest.streetNumber = formData.houseNr;
	membershipRequest.mailboxNumber = formData.bus;
	membershipRequest.city = formData.city;
	membershipRequest.zip = formData.postalCode;
	if (membershipRequest.partner) {
		membershipRequest.partner.street = formData.address;
		membershipRequest.partner.streetNumber = formData.houseNr;
		membershipRequest.partner.mailboxNumber = formData.bus;
		membershipRequest.partner.city = formData.city;
		membershipRequest.partner.zip = formData.postalCode;
	}
}

function setMemberPartnerPersonalInfo(formData: step1Form) {
	membershipRequest.partner = { isExistingMember: false };
	if (formData.memberNumberExtra) {
		membershipRequest.partner.isExistingMember = true;
		membershipRequest.partner.memberNumber = formData.memberNumberExtra;
	} else {
		membershipRequest.partner.lastName = formData.lastnameExtra;
		membershipRequest.partner.firstName = formData.firstNameExtra;
		membershipRequest.partner.gender = formData.genderExtra;
		membershipRequest.partner.birthDate = formatDate(formData.birthDateExtra);
		membershipRequest.partner.email = formData.emailExtra;
		membershipRequest.partner.phoneNumber = formData.phoneNumberExtra;
	}
}

function formatDate(date: string) {
	var dateSegments = date.split("/");
	return new Date(`${dateSegments[2]}-${dateSegments[1]}-${dateSegments[0]}`).toISOString().split('T')[0];
}
function clickedPrevious() {
	isStep2Active.value = false;
	isStep1Active.value = true;
}
function requestMembership() {
	membershipPloc.RequestMembership(props.clubId, membershipRequest).then(() => {
		if (membershipState.value.kind == "LoadedState") {
			if (membershipState.value.data.redirectUrl != null) {
				window.location.replace(membershipState.value.data.redirectUrl);
			}
			isLoading.value = false;
		} else if (membershipState.value.kind == "ErrorState") {
			if (membershipState.value.error != null) {
				errorMessage.value = membershipState.value.error.message;
			}
			isLoading.value = false;
		}
	});
}
</script>
<template>
	<div class="flex flex-col vp7:flex-row gap-4 justify-between section-b-md">
		<div class="grid gap-y-4 vp7:max-w-[560px] order-2 vp7:order-1">
			<Step1Vue :is-active="isStep1Active" @clicked-next="(payload) => clickedNextStep1(payload)" :error-message="errorMessage" />
			<Step2Vue
				:is-loading="isLoading"
				:is-active="isStep2Active"
				@clicked-previous="clickedPrevious"
				@clicked-next="(formData) => clickedNextStep2(formData)"
				:error-message="errorMessage"
			/>
		</div>

		<div class="vp7:max-w-[420px] order-1 vp7:order-2">
			<div class="flex flex-col gap-6 bg-grey-05 shape-tr-sm px-8 py-6">
				<h3 class="typo-h4">{{ translations.overzicht }}</h3>

				<div class="">
					<div class="typo-strong">{{ translations.inschrijvenLidmaatschap }} {{ clubName }}</div>
					<div class="border-b border-grey-100 py-1">{{ new Date().getFullYear() }}</div>
					<div class="grid grid-cols-4 py-1">
						<div class="col-span-3">{{ translations.nieuwLid }} 1</div>
						<div class="col-span-1 text-end">€{{ amount }}</div>
					</div>
					<div v-if="extraMember" class="grid grid-cols-4 py-1">
						<div class="col-span-3">{{ translations.nieuwLid }} 2</div>
						<div class="col-span-1 text-end">€{{ amount }}</div>
					</div>
					<div class="grid grid-cols-4 py-1">
						<div class="col-span-3 typo-strong">{{ translations.totaal }}</div>
						<div class="col-span-1 typo-strong text-end">€{{ totalAmount }}</div>
					</div>
				</div>
			</div>

			<div v-if="helpNeededText" class="s-wysiwyg px-6 py-4 border border-grey-20 mt-4 vp7:mt-6">
				<b>{{ translations.hulpNodig }}</b> {{helpNeededText}}
			</div>
		</div>
	</div>
</template>
