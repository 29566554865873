<script setup lang="ts">
import { Button, ComponentScheme, ComponentSize, ComponentType, Icon, Link, SelectOption, step1Form } from "@/entities";
import { LinkVue, IconVue, ButtonVue, SelectVue } from "../common";
import { emailRegex} from "@/utils";
import { ref, defineEmits } from "vue";
import { vMaska } from 'maska'
defineProps<{
    isActive: boolean;
    errorMessage: string | undefined;
}>();
const emits = defineEmits<{
    (e: 'clicked-next', payload: { hasExtra: boolean, formData: step1Form }): void;
}>();
const linkForgot: Link = { text: "Lidnummer vergeten?", url: "#", scheme: ComponentScheme.Blue, size: ComponentSize.Small, classes: "c-link--bold ml-auto" }
const iconInfo: Icon = { size: ComponentSize.Small, src: "/assets/icons/info.svg", classes: "c-helper-text__icon" }
const iconRemove: Icon = { size: ComponentSize.Small, src: "/assets/icons/x.svg" }
const genderOptions: SelectOption[] = [{ label: "Man", value: "Male" }, { label: "Vrouw", value: "Female" }, { label: "Anders", value: "Other" },]

const regexBirthDate = "[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}";

const additionalMember = ref(false);
const hasMemberNumber = ref(false);
const hasMemberNumberExtra = ref(false);

const linkAdd: Link = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Small,
    classes: "c-step__link c-link--bold",
    text: "voeg een tweede persoon op hetzelfde adres toe (vb koppel, broer en zus, …)",
    iconBefore: {
        size: ComponentSize.Small,
        src: "/assets/icons/plus.svg"
    }
}
const buttonPrevious: Button = {
    scheme: ComponentScheme.Default, size: ComponentSize.Default, type: ComponentType.Secondary, text: "Vorige", classes: "disabled", tag: "button"
}
const buttonNext: Button = {
    scheme: ComponentScheme.Default, size: ComponentSize.Default, type: ComponentType.Primary, text: "Volgende", tag: 'button'
}

const form: step1Form = {
    memberNumber: '',
    lastname: '',
    firstName: '',
    gender: '',
    birthDate: '',
    email: '',
    phoneNumber: '',
    memberNumberExtra: '',
    lastnameExtra: '',
    firstNameExtra: '',
    genderExtra: '',
    birthDateExtra: '',
    emailExtra: '',
    phoneNumberExtra: ''
}
function clickNext() {
    emits("clicked-next", { hasExtra: additionalMember.value, formData: form })
}
</script>
<template>
    <li class="c-step">
        <div class="c-step__title">1. Persoonlijke gegevens</div>
        <form @submit.prevent="clickNext" class="c-step__container" v-if="isActive" autocomplete="off">
            <div class="c-step__container-item" v-if="!hasMemberNumber">
                <div v-if="additionalMember" class="c-step__container-title">Lid 1</div>
                <div class="c-step__container-info">
                    <div class="c-input-group">

                        <label for="name" class="c-label">
                            Voornaam
                        </label>

                        <input v-model="form.firstName" class="c-input" type="text" id="name" name="name"
                            placeholder="Annelies" required>

                    </div>
                    <div class="c-input-group">

                        <label for="lastname" class="c-label">
                            Achternaam
                        </label>

                        <input v-model="form.lastname" class="c-input" type="text" id="lastname" name="lastname"
                            placeholder="De Ruyck" required>

                    </div>

                    <div class="c-input-group">

                        <label for="gender" class="c-label">
                            Geslacht
                        </label>

                        <SelectVue :placeholder="'Selecteer een optie'" :options="genderOptions"
                            :model-value="form.gender" @update:model-value="$event => (form.gender = $event)" />

                    </div>
                    <div class="c-input-group">
                        <label class="c-label" for="birthdate">
                            Geboortedatum
                        </label>
                        <input v-model="form.birthDate" v-maska data-maska="##/##/####" class="c-input" type="text"
                            id="birthdate" name="birthdate" placeholder="DD/MM/JJJJ" required :pattern="regexBirthDate">

                    </div>
                    <div class="c-input-group">

                        <label for="email" class="c-label">
                            E-mailadres
                        </label>

                        <input v-model="form.email" class="c-input" type="email" :pattern="emailRegex.toString()" id="email" name="email"
                            placeholder="annelies.dr@mail.com" required>

                    </div>
                    <div class="c-input-group">

                        <label for="phonenumber" class="c-label">
                            Telefoonnummer
                        </label>

                        <input v-model="form.phoneNumber" v-maska data-maska="+32 ### ## ## ##" class="c-input"
                            type="tel" id="phonenumber" name="phonenumber" placeholder="+32 474 74 74 74" required
                            pattern="^\+[0-9]{2}\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}\s[0-9]{2}$">

                    </div>
                </div>
                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__items">
                        <div class="c-checkbox-group__item">
                            <input class="c-checkbox-group__input" type="checkbox" id="memberNumber"
                                v-model="hasMemberNumber" name="memberNumber">
                            <label class="c-checkbox-group__label" for="memberNumber">
                                <span class="c-checkbox-group__icon"></span>

                                <span>
                                    Ik ben lid
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <LinkVue :link="linkAdd" v-if="!additionalMember" @click="additionalMember = true" />

                <span class="c-step__helper c-helper-text c-helper-text__icon" v-if="!additionalMember">
                    <IconVue :icon="iconInfo" />
                    Je kan enkel een partner inschrijven die op hetzelfde adres woont. Partners die
                    reeds lid zijn
                    van Neos, moet je hier niet toevoegen.
                </span>
            </div>
            <div class="c-step__container-item" v-if="hasMemberNumber">
                <div class="c-input-group">
                    <label for="membershipNumber" class="c-label">
                        Lidnummer
                    </label>

                    <span class="c-helper-text">
                        <IconVue :icon="iconInfo" />
                        Je lidnummer kan je vinden op je lidkaart of in de mails van Neos.
                    </span>

                    <input v-model="form.memberNumber" class="c-input" type="text" id="membershipNumber"
                        name="membershipNumber" placeholder="vandega123" required>
                    <LinkVue :link="linkForgot" />
                </div>
                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__items">
                        <div class="c-checkbox-group__item">
                            <input class="c-checkbox-group__input" type="checkbox" id="memberNumber"
                                v-model="hasMemberNumber" name="memberNumber">
                            <label class="c-checkbox-group__label" for="memberNumber">
                                <span class="c-checkbox-group__icon"></span>

                                <span>
                                    Ik ben lid
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <LinkVue :link="linkAdd" v-if="!additionalMember" @click="additionalMember = true" />

                <span class="c-step__helper c-helper-text" v-if="!additionalMember">
                    <IconVue :icon="iconInfo" />
                    Je kan enkel een partner inschrijven die op hetzelfde adres woont. Partners die
                    reeds lid zijn
                    van Neos, moet je hier niet toevoegen.
                </span>
            </div>
            <div class="c-step__container-item" v-if="additionalMember && !hasMemberNumberExtra">
                <div class="c-step__container-title">Lid 2
                    <IconVue :icon="iconRemove" @click="additionalMember = false" style="display: inline-block;" />
                </div>
                <div class="c-step__container-info">
                    <div class="c-input-group">

                        <label for="name" class="c-label">
                            Voornaam
                        </label>

                        <input v-model="form.firstNameExtra" class="c-input" type="text" id="name" name="name"
                            placeholder="Annelies" required>

                    </div>
                    <div class="c-input-group">

                        <label for="lastname" class="c-label">
                            Achternaam
                        </label>

                        <input v-model="form.lastnameExtra" class="c-input" type="text" id="lastname" name="lastname"
                            placeholder="De Ruyck" required>

                    </div>

                    <div class="c-input-group">

                        <label for="gender" class="c-label">
                            Geslacht
                        </label>

                        <SelectVue :placeholder="'Selecteer een optie'" :options="genderOptions"
                            :model-value="form.genderExtra"
                            @update:model-value="$event => (form.genderExtra = $event)" />

                    </div>
                    <div class="c-input-group">
                        <label class="c-label" for="birthdate">
                            Geboortedatum
                        </label>
                        <input v-model="form.birthDateExtra" v-maska data-maska="##/##/####" class="c-input" type="text"
                            id="birthdate" name="birthdate" placeholder="DD/MM/JJJJ" required :pattern="regexBirthDate">

                    </div>
                    <div class="c-input-group">

                        <label for="email" class="c-label">
                            E-mailadres
                        </label>

                        <input v-model="form.emailExtra" class="c-input" type="email" :pattern="emailRegex.toString()" id="email" name="email"
                            placeholder="annelies.dr@mail.com" required>

                    </div>
                    <div class="c-input-group">

                        <label for="phonenumber" class="c-label">
                            Telefoonnummer
                        </label>

                        <input v-model="form.phoneNumberExtra" v-maska data-maska="+32 ### ## ## ##" class="c-input"
                            type="tel" id="phonenumber" name="phonenumber" placeholder="+32 474 74 74 74" required
                            pattern="^\+[0-9]{2}\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}\s[0-9]{2}$">

                    </div>
                </div>
                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__items">
                        <div class="c-checkbox-group__item">
                            <input class="c-checkbox-group__input" type="checkbox" id="extraMemberNumber"
                                v-model="hasMemberNumberExtra" name="extraMemberNumber">
                            <label class="c-checkbox-group__label" for="extraMemberNumber">
                                <span class="c-checkbox-group__icon"></span>

                                <span>
                                    Ik ben lid
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-step__container-item" v-if="additionalMember && hasMemberNumberExtra">
                <div class="c-step__container-title">Lid 2
                    <IconVue :icon="iconRemove" @click="additionalMember = false" style="display: inline-block;" />
                </div>

                <div class="c-input-group">
                    <label for="membershipNumberExtra" class="c-label">
                        Lidnummer
                    </label>

                    <span class="c-helper-text">
                        <IconVue :icon="iconInfo" />
                        Je lidnummer kan je vinden op je lidkaart of in de mails van Neos.
                    </span>

                    <input v-model="form.memberNumberExtra" class="c-input" type="text" id="membershipNumberExtra"
                        name="membershipNumberExtra" placeholder="vandega123" required>
                    <LinkVue :link="linkForgot" />
                </div>
                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__items">
                        <div class="c-checkbox-group__item">
                            <input class="c-checkbox-group__input" type="checkbox" id="extraMemberNumber"
                                v-model="hasMemberNumberExtra" name="extraMemberNumber">
                            <label class="c-checkbox-group__label" for="extraMemberNumber">
                                <span class="c-checkbox-group__icon"></span>

                                <span>
                                    Ik ben lid
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-step__buttons" v-if="isActive">
                <ButtonVue :button="buttonPrevious" disabled />
                <ButtonVue :button="buttonNext" type="submit" />
            </div>
            <p v-if="errorMessage" class="c-errorMessage">{{ errorMessage }}</p>
        </form>

    </li>
</template>