<script setup lang="ts">
import { Card, ComponentScheme, ComponentSize, DataList, Icon, Link, Tag } from '@/entities';
import { CardVue, HeroVue, PaginationVue } from '../common';
import { Ref, inject, onMounted, reactive, ref } from 'vue';
import { EVENT_PLOC, usePlocState } from '@/utils';
import { EventItem, EventRequest, PagedResponse } from 'neos-core';
import moment from "moment";
import EventFilter from './EventFilter.vue';

const eventPloc = inject(EVENT_PLOC)!;
const eventState = ref(usePlocState(eventPloc));

const props = defineProps<{
	themeOptions: any[],
	regionOptions: any[],
	contentId: string
	title: string
	breadCrumbs: any[]
}>();

const themeFilter: Ref<{ [key: number]: string }> = ref({});
const regionFilter: Ref<string[]> = ref([]);
const frequencyFilter: Ref<string[]> = ref([]);
const audienceFilter: Ref<string> = ref("Voor iedereen");
const datesFilter: Ref<string[]> = ref([]);

const eventRequest: Ref<EventRequest> = ref({
	pageSize: 10,
	page: 1,
	contentId: props.contentId
})

const eventItems = reactive<PagedResponse<EventItem[]>>({
	results: [],
	pagination: {
		amountOfPages: 1,
		currentPage: 1,
		pageSize: 10,
		totalAmount: 1,
		firstRowOnPage: 1,
		lastRowOnPage: 1,
	},
})

const breadCrumbsAttributes: Record<string, string> = {};
breadCrumbsAttributes["data-aos"] = "fade-up";

function loadEvents() {
	eventPloc.GetEventItems(eventRequest.value).then(() => {
		if (eventState.value.kind === "LoadedState") {
			eventItems.results = eventState.value.data as EventItem[];
			eventItems.pagination = eventState.value.pagination!;
		} else if (eventState.value.kind === "ErrorState") {
			eventItems.pagination.amountOfPages = 0;
			eventItems.pagination.currentPage = 0;
			eventItems.pagination.totalAmount = 0;
			eventItems.pagination.firstRowOnPage = 0;
			eventItems.pagination.lastRowOnPage = 0;
			eventItems.results = [];
			console.log("Error: " + eventState.value.error);
		}
	});
}

function createCard(event: EventItem) {
	let link: Link = { text: "Meer info", url: event.url, scheme: ComponentScheme.Blue, size: ComponentSize.Default }
	const tag: Tag = { scheme: event.tagColor, text: event.tag };
	const iconJournal: Icon = { size: ComponentSize.Default, src: "/assets/icons/journal.svg" }
	const iconCalendar: Icon = { size: ComponentSize.Default, src: "/assets/icons/calendar-blank.svg" }
	const iconShield: Icon = { size: ComponentSize.Default, src: "/assets/icons/shield.svg" }
	const items = new Map<Icon, string>();
	items.set(iconCalendar, moment(event.date).format("DD-MM-yyyy"))
	items.set(iconShield, event.location);
	const dataList: DataList = {
		isPrivate: false,
		items: items
	}
	const card: Card = {
		title: event.title,
		text: event.description,
		tag: tag,
		link: link,
		icon: iconJournal,
		dataList: dataList,
		classes: "aos-init aos-animate",
		image: { src: event.image, alt: "image" }
	};

	return card;
}

function applyThemeFilter(themes: string[]) {
	themeFilter.value = themes;
	eventRequest.value.themes = themes.join("/");
	resetPage();
	loadEvents();
}
function applyRegionFilter(regions: string[]) {
	regionFilter.value = regions;
	eventRequest.value.region = regions.join("/");
	resetPage();
	loadEvents();
}
function applyAudienceFilter(target: string) {
	audienceFilter.value = target;
	eventRequest.value.targetAudience = target;
	resetPage();
	loadEvents();
}
function applyFrequencyFilter(frequencies: string[]) {
	frequencyFilter.value = frequencies;
	eventRequest.value.frequency = frequencies.join("/");
	resetPage();
	loadEvents();
}
function changePage(page: number) {
	eventRequest.value.page = page;
	loadEvents();
}
function applyDateFilter(dates: any[]) {
	if (dates[1]) {
		datesFilter.value = dates.map((date: string) => {
			return moment(date).format("yyyy-MM-DD")
		})
		eventRequest.value.startDate = datesFilter.value[0];
		eventRequest.value.endDate = datesFilter.value[1];
	}
	else {
		datesFilter.value = []
	}
	resetPage();
	loadEvents();
}

function resetPage(){
	eventRequest.value.page = 1;
	eventItems.pagination.currentPage = 1;
}

onMounted(() => {
	loadEvents();
})
</script>
<template>
	<HeroVue :bread-crumbs="breadCrumbs" :title="title" />
	<section class="layout layout-bound-b container-grid gap-y-4">
		<div class="pt-3 vp7:end-4 vp5:end-5 vp9:pr-10 vp5:pr-3">
			<EventFilter :theme-options="themeOptions" :region-options="regionOptions"
				@themes-filter-changed="(themes: string[]) => applyThemeFilter(themes)"
				@regions-filter-changed="(regions: string[]) => applyRegionFilter(regions)"
				@frequency-filter-changed="(frequencies: string[]) => applyFrequencyFilter(frequencies)"
				@audience-filter-changed="(targetAudience: string) => applyAudienceFilter(targetAudience)"
				@date-filter-changed="(dates: any) => applyDateFilter(dates)" />
		</div>

		<div class="vp7:start-5 vp5:start-6 vp5:end-12 flex flex-col gap-y-10">
			<div class="border-b border-b-grey-10 pt-3 pb-3">
				<span class="text-grey-65">{{ eventItems.pagination.totalAmount }} resultaten</span>
			</div>

			<div class="grid gap-y-10 grow">
				<section class="grid vp7:grid-cols-2 gap-4 h-fit">
					<CardVue v-for="event in eventItems.results" :card="createCard(event)" />
				</section>
			</div>

			<div class="flex justify-center">
				<PaginationVue
					:pagination="{ page: eventItems.pagination.currentPage, amountOfPages: eventItems.pagination.amountOfPages }"
					:max-number-of-page-buttons="6" @page-changed="(page) => {
						changePage(page)
					}" />
			</div>
		</div>
	</section>
</template>
