<script setup lang="ts">
import { Card, ComponentScheme, ComponentSize, ComponentType, Link } from '@/entities';
import TagVue from './Tag.vue';
import { computed, ref } from 'vue';
import { IconVue, LinkVue } from '.';
const props = defineProps<{
    card: Card
}>();
const clickable = ref(true);
const targetClass = ref("");
const triggerClass = ref("");

const linkDefault: Link = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Default,
    text: props.card.dataList?.defaultText,
    classes: `${triggerClass.value} c-card-default__trigger c-link--underline is-active toggle-target`,
    iconButtonAfter: {
        scheme: ComponentScheme.Blue,
        size: ComponentSize.Small,
        type: ComponentType.Secondary,
        classes: triggerClass.value,
        tag: "button",
        icon: { size: ComponentSize.Default, src: "/assets/icons/plus.svg" }
    }
};

const linkActive: Link = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Default,
    text: props.card.dataList?.activetext,
    classes: `${triggerClass.value} c-card-default__trigger c-link--underline toggle-target`,
    iconButtonAfter: {
        scheme: ComponentScheme.Blue,
        size: ComponentSize.Small,
        type: ComponentType.Secondary,
        classes: triggerClass.value,
        tag: "button",
        icon: { size: ComponentSize.Default, src: "/assets/icons/minus.svg" }
    }
};

const tag = computed(() => {
    return (props.card.dataList && props.card.dataList.isPrivate) ? "article" : "a";
})
const attributes = computed(() => {
    let attributes: Record<string, string> = {};
    if (props.card.attributes) {
        attributes = props.card.attributes
    }
    if (props.card.dataList && props.card.dataList.isPrivate) {
        clickable.value = false;
        attributes["data-trigger"] = "toggle-trigger";
        attributes["data-target"] = "toggle-target";
        attributes["data-class"] = "is-active";
        targetClass.value = "toggle-target"
        triggerClass.value = "toggle-trigger"
    }
    if (props.card.link && props.card.link.url && clickable.value) {
        attributes["href"] = props.card.link.url;
    }
    return attributes
})

const link = computed(() => {
    if (props.card.link) {
        const link = props.card.link;
        link.classes = link.classes ? link.classes?.concat(" c-link--underline c-card-default__trigger") : " c-link--underline c-card-default__trigger"
        link.url = props.card.link.url;
        link.iconButtonAfter = {
            type: ComponentType.Secondary,
            scheme: ComponentScheme.Blue,
            size: ComponentSize.Small,
            classes: link.target == "_blank" ? "-rotate-45" : "",
            icon: { size: ComponentSize.Small, src: "/assets/icons/arrow-right.svg" }
        }
        return link
    }

    return props.card.link;
})
</script>
<template>
    <tag v-bind="attributes" :class="`c-card-default ${card.classes}`">
        <div v-if="card.image" class="c-card-default__image">
            <picture class="c-image c-image--cover">
                <img class="c-image__item" :src="card.image.src" :alt="card.image.alt">
            </picture>
            <div v-if="card.tag" class="c-card-default__tag">
                <TagVue :tag="card.tag" />
            </div>
        </div>
        <div v-else-if="card.tag" class="c-card-default__tag">
            <TagVue :tag="card.tag" />
        </div>
        <div class="c-card-default__content">
            <div class="c-card-default__header">
                <h2 class="c-card-default__title">{{ card.title }}</h2>
                <h3 v-if="card.subtitle" class="c-card-default__subtitle">{{ card.subtitle }}</h3>
            </div>
            <p v-if="card.text" class="c-card-default__text">{{ card.text }}</p>
            <div v-if="card.dataList && card.dataList.items && card.dataList.items.size > 0"
                :class="`c-card-default__datalist ${targetClass}`">
                <div v-for="[key, value] in card.dataList.items" class="flex gap-1">
                    <IconVue :icon="key" />
                    <p class="type-p-sm">
                        <b>
                            {{ value }}
                        </b>
                    </p>
                </div>
            </div>
            <template v-if="card.dataList && card.dataList.isPrivate">
                <LinkVue :link="linkDefault" />
                <LinkVue :link="linkActive" />
            </template>
            <template v-else-if="link">
                <LinkVue :link="link" />
            </template>
        </div>
    </tag>
</template>