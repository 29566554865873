<script setup lang="ts">
import { Button, ComponentScheme, ComponentSize, ComponentType, Icon, Link } from "@/entities";
import type { MemberSignupForm } from "@/entities";
import { LinkVue, IconVue, ButtonVue } from "../common";
import { ref, defineEmits, watchEffect } from "vue";
import { Translations } from "@/entities/ActivityTranslations";
import { emailRegex} from "@/utils";
import { vMaska } from 'maska'
const props = defineProps<{
	isActive: boolean;
	translations: Translations;
	errorMessage: string | undefined;
	isLoading: boolean;
}>();
const emits = defineEmits<{
	(e: "clicked-next", payload: MemberSignupForm): void;
	(e: "clicked-extra", payload: MemberSignupForm): void;
	(e: "input-membernumber", payload: MemberSignupForm): void;
}>();
const loading = ref(props.isLoading);

watchEffect(() => {
    loading.value = props.isLoading;
});

const iconInfo: Icon = { size: ComponentSize.Small, src: "/assets/icons/info.svg", classes: "c-helper-text__icon" };

const regexBirthDate = "[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}";

const linkAdd: Link = {
	scheme: ComponentScheme.Blue,
	size: ComponentSize.Small,
	classes: "c-step__link c-link--bold",
	text: "Voeg een deelnemer toe",
	iconBefore: {
		size: ComponentSize.Small,
		src: "/assets/icons/plus.svg",
	},
};
const buttonPrevious: Button = {
	scheme: ComponentScheme.Default,
	size: ComponentSize.Default,
	type: ComponentType.Secondary,
	text: "Vorige",
	classes: "disabled",
	tag: "button",
};
const buttonNext: Button = {
	scheme: ComponentScheme.Default,
	size: ComponentSize.Default,
	type: ComponentType.Primary,
	text: "Volgende",
	tag: "button",
};
const formRef = ref<MemberSignupForm>({
	members: [
		{
			birthDate: "",
			email: "",
			firstName: "",
			isMember: false,
			lastName: "",
			memberNumber: "",
		},
	],
});

function addMember() {
	formRef.value.members.push({
		birthDate: "",
		email: "",
		firstName: "",
		isMember: false,
		lastName: "",
		memberNumber: "",
	});
	emits("clicked-extra", formRef.value);
}
function memberNumberInput(){
	emits("input-membernumber", formRef.value);
}

function clickNext() {
	emits("clicked-next", formRef.value);
}
</script>
<template>
	<li class="c-step">
		<div class="c-step__title">{{ translations.gegevens }}</div>
		<form @submit.prevent="clickNext" class="c-step__container" v-if="isActive" autocomplete="off">
			<div v-for="(member, index) in formRef.members">
				<div class="c-step__container-item" v-if="!formRef.members[index].isMember">
					<div class="c-step__container-title">Deelnemer {{ index + 1 }}</div>
					<div class="c-step__container-info">
						<div class="c-input-group">
							<label :for="'name' + index" class="c-label"> Voornaam </label>

							<input
								v-model="formRef.members[index].firstName"
								class="c-input"
								type="text"
								:id="'name' + index"
								:name="'name' + index"
								placeholder="Annelies"
								@input="memberNumberInput"
								required
							/>
						</div>
						<div class="c-input-group">
							<label :for="'lastname' + index" class="c-label"> Achternaam </label>

							<input
								v-model="formRef.members[index].lastName"
								class="c-input"
								type="text"
								:id="'lastname' + index"
								:name="'lastname' + index"
								placeholder="De Ruyck"
								@input="memberNumberInput"
								required
							/>
						</div>

						<div class="c-input-group">
							<label class="c-label" :for="'birthdate' + index"> Geboortedatum </label>
							<input
								v-model="formRef.members[index].birthDate"
								v-maska data-maska="##/##/####"
								class="c-input"
								type="text"
								:id="'birthdate' + index"
								:name="'birthdate' + index"
								placeholder="DD/MM/JJJJ"
								required
								:pattern="regexBirthDate"
							/>
						</div>
						<div class="c-input-group">
							<label :for="'email' + index" class="c-label"> E-mailadres </label>

							<input
								v-model="formRef.members[index].email"
								class="c-input"
								type="email"
								:id="'email' + index"
								:name="'email' + index"
								:pattern="emailRegex.toString()" 
								placeholder="annelies.dr@mail.com"
								required
							/>
						</div>
					</div>
					<div class="c-checkbox-group">
						<div class="c-checkbox-group__items">
							<div class="c-checkbox-group__item">
								<input
									class="c-checkbox-group__input"
									type="checkbox"
									:id="'memberNumber' + index"
									v-model="formRef.members[index].isMember"
									:name="'memberNumber' + index"
								/>
								<label class="c-checkbox-group__label" :for="'memberNumber' + index">
									<span class="c-checkbox-group__icon"></span>

									<span> Ik ben lid </span>
								</label>
							</div>
						</div>
					</div>
					<LinkVue :link="linkAdd" @click="addMember" />
				</div>
				<div class="c-step__container-item" v-if="formRef.members[index].isMember">
					<div class="c-input-group">
						<label for="membershipNumber" class="c-label"> Lidnummer </label>

						<span class="c-helper-text">
							<IconVue :icon="iconInfo" />
							Als lid kan je inschrijven met je lidnummer. Heb je je lidnummer niet bij de hand kan je je inschrijven aan de hand van je gegevens zoals een niet lid.
							Ben je geen lid gelieve dan onderstaande gegevens in te vullen.
						</span>

						<input
							v-model="formRef.members[index].memberNumber"
							class="c-input"
							type="text"
							:id="'membershipNumber' + index"
							:name="'membershipNumber' + index"
							placeholder="vandega123"
							@input="memberNumberInput"
							required
						/>
					</div>
					<div class="c-checkbox-group">
						<div class="c-checkbox-group__items">
							<div class="c-checkbox-group__item">
								<input
									class="c-checkbox-group__input"
									type="checkbox"
									:id="'memberNumber' + index"
									v-model="formRef.members[index].isMember"
									:name="'memberNumber' + index"
								/>
								<label class="c-checkbox-group__label" :for="'memberNumber' + index">
									<span class="c-checkbox-group__icon"></span>

									<span> Ik ben lid </span>
								</label>
							</div>
						</div>
					</div>
					<LinkVue :link="linkAdd" @click="addMember" />
				</div>
			</div>
			<div class="c-step__buttons" v-if="isActive">
				<ButtonVue :button="buttonPrevious" disabled />
				<ButtonVue v-if="isLoading" :button="buttonNext" disabled />
                <ButtonVue v-else :button="buttonNext" type="submit"/>
			</div>
            <p v-if="errorMessage" class="c-errorMessage">{{ errorMessage }}</p>
		</form>
	</li>
</template>
